import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path0/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Back Squat 4×6\\@65%1RM w/:02 pause at bottom of each rep`}</p>
    <p>{`Ball Hamstring Curls 4×12`}</p>
    <p>{`then,`}</p>
    <p>{`4 Rounds for time of:`}</p>
    <p>{`20-Wall Balls (20/14)(Rx+30/20)`}</p>
    <p>{`15-Calorie Row`}</p>
    <p>{`5-Bear Complex (135/95)(RX+155/105)`}</p>
    <p><em parentName="p">{`*`}{`Power Clean+Front Squat+Push Press+Back Squat = 1 rep`}</em></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      